<template>
  <div class="dashboard-sidebar" :class="{ closed: sidebarIsClosed }">
    <div class="v-overlay" @click="toggleSidebarStatus()">
      <div class="v-overlay__scrim"></div>
    </div>

    <div class="sidebar">
      <header>
        <span class="header-image">
          <img src="./../../assets/images/logo.png" alt="" />
        </span>

        <div class="header-text">
          <span class="name">{{ $t("projectName") }}</span>
        </div>
      </header>

      <div class="menu">
        <ul class="menu-links">
          <li
            class="animate__animated animate__fadeInUpBig"
            v-for="(route, index) in routes"
            :key="index"
            :class="
              !route.childrens || route.childrens.length == 0 ? '' : 'sub-menu'
            "
          >
            <router-link
              v-if="!route.childrens || route.childrens.length == 0"
              :to="{ name: route.name }"
            >
              <span class="icon"
                ><img :src="route.icon" v-b-popover.hover.top="route.title"
              /></span>
              <span class="text">{{ route.title }}</span>
            </router-link>

            <template v-else>
              <b-button v-b-toggle="route.name">
                <span class="icon"
                  ><img :src="route.icon" v-b-popover.hover.top="route.title"
                /></span>
                <span class="text">
                  <span class="sub-text">{{ route.title }}</span>
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </span>
              </b-button>
              <b-collapse :id="route.name">
                <li v-for="(route, index) in route.childrens" :key="index">
                  <router-link :to="{ name: route.name }">
                    <span class="icon"
                      ><img
                        :src="route.icon"
                        v-b-popover.hover.top="route.title"
                    /></span>
                    <span class="text">{{ route.title }}</span>
                  </router-link>
                </li>
              </b-collapse>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { checkPrivilege } from "./../../utils/functions";
import {
  hasUsers,
  hasMasterAdmin,
  hasEmployee,
  hasStudent,
  hasParent,
  hasMasterAdminEstablishmentsRole,
  hasMasterAdminLoginCode,
  hasEmployeeEstablishmentsRole,
  hasEmployeeLoginCode,
  hasStudentEstablishmentsRole,
  hasStudentLoginCode,
  hasParentEstablishmentsRole,
  hasParentLoginCode,
  hasSettingsOthers,
} from "./../../utils/privilegeHelper";

import iconMasterAdmins from "./../../assets/images/masterAdmins.svg";
import iconEmployees from "./../../assets/images/employees.svg";
import iconStudents from "./../../assets/images/students.svg";
import iconParents from "./../../assets/images/parents.svg";
import iconSettingsOthers from "./../../assets/images/settings-other.svg";

export default {
  computed: {
    ...mapGetters(["sidebarIsClosed"]),

    routes: function () {
      let theRoutes = [];

      //#region UsersModule
      if (checkPrivilege(hasUsers())) {
        if (checkPrivilege(hasStudent())) {
          if (checkPrivilege(hasStudent())) {
            theRoutes.push({
              name: "Students",
              icon: iconStudents,
              title: this.$t("students.modelName"),
            });
          } else if (checkPrivilege(hasStudentEstablishmentsRole())) {
            theRoutes.push({
              name: "StudentEstablishmentsRole",
              icon: iconStudents,
              title: this.$t("students.modelName"),
            });
          } else if (checkPrivilege(hasStudentLoginCode())) {
            theRoutes.push({
              name: "StudentLoginCode",
              icon: iconStudents,
              title: this.$t("students.modelName"),
            });
          }
        }

        if (checkPrivilege(hasParent())) {
          if (checkPrivilege(hasParent())) {
            theRoutes.push({
              name: "Parents",
              icon: iconParents,
              title: this.$t("parents.modelName"),
            });
          } else if (checkPrivilege(hasParentEstablishmentsRole())) {
            theRoutes.push({
              name: "ParentEstablishmentsRole",
              icon: iconParents,
              title: this.$t("parents.modelName"),
            });
          } else if (checkPrivilege(hasParentLoginCode())) {
            theRoutes.push({
              name: "ParentLoginCode",
              icon: iconParents,
              title: this.$t("parents.modelName"),
            });
          }
        }

        if (checkPrivilege(hasEmployee())) {
          if (checkPrivilege(hasEmployee())) {
            theRoutes.push({
              name: "Employees",
              icon: iconEmployees,
              title: this.$t("employees.modelName"),
            });
          } else if (checkPrivilege(hasEmployeeEstablishmentsRole())) {
            theRoutes.push({
              name: "EmployeeEstablishmentsRole",
              icon: iconEmployees,
              title: this.$t("employees.modelName"),
            });
          } else if (checkPrivilege(hasEmployeeLoginCode())) {
            theRoutes.push({
              name: "EmployeeLoginCode",
              icon: iconEmployees,
              title: this.$t("employees.modelName"),
            });
          }
        }

        if (checkPrivilege(hasMasterAdmin())) {
          if (checkPrivilege(hasMasterAdmin())) {
            theRoutes.push({
              name: "MasterAdmin",
              icon: iconMasterAdmins,
              title: this.$t("masterAdmins.modelName"),
            });
          } else if (checkPrivilege(hasMasterAdminEstablishmentsRole())) {
            theRoutes.push({
              name: "MasterAdminEstablishmentsRole",
              icon: iconMasterAdmins,
              title: this.$t("masterAdmins.modelName"),
            });
          } else if (checkPrivilege(hasMasterAdminLoginCode())) {
            theRoutes.push({
              name: "MasterAdminLoginCode",
              icon: iconMasterAdmins,
              title: this.$t("masterAdmins.modelName"),
            });
          }
        }
      }
      //#endregion UsersModule

      if (checkPrivilege(hasSettingsOthers()))
        theRoutes.push({
          name: "SettingsOthers",
          icon: iconSettingsOthers,
          title: this.$t("settings-other"),
        });

      return theRoutes;
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["toggleSidebarStatus"]),
    getCurrentActive() {
      setTimeout(() => {
        const allButtons = document.querySelectorAll(".menu-links li button");
        if (allButtons != undefined && allButtons.length != 0) {
          allButtons.forEach(function (item) {
            item.classList.remove("active");
          });
        }
        const allSelectors = document.querySelectorAll(".router-link-active");
        if (allSelectors != undefined && allSelectors.length != 0) {
          allSelectors.forEach(function (item) {
            if (item.parentNode.parentNode.previousSibling)
              item.parentNode.parentNode.previousSibling.classList.add(
                "active"
              );
          });
        }
      }, 1000);
    },
  },
  watch: {
    $route() {
      this.getCurrentActive();
    },
  },
  async created() {
    this.getCurrentActive();
  },
};
</script>
