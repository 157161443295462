import GeneralSettingModel from "./GeneralSettingModel";

export default class GeneralSetting {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.categories = new GeneralSettingModel();
    this.products = new GeneralSettingModel();
    this.userWalletTransactions = new GeneralSettingModel();
    this.educationalStages = new GeneralSettingModel();

    this.users = new GeneralSettingModel();
    this.masterAdmins = new GeneralSettingModel();
    this.employees = new GeneralSettingModel();
    this.students = new GeneralSettingModel();
    this.suppliers = new GeneralSettingModel();
    this.parents = new GeneralSettingModel();
    this.clients = new GeneralSettingModel();
    this.adminsEgypt = new GeneralSettingModel();

    this.adminsEgyptFamily = new GeneralSettingModel();
    this.masterAdminFamily = new GeneralSettingModel();
    this.employeeFamily = new GeneralSettingModel();
    this.studentFamily = new GeneralSettingModel();
    this.supplierFamily = new GeneralSettingModel();
    this.parentFamily = new GeneralSettingModel();
    this.clientFamily = new GeneralSettingModel();

    // valid settings not exist
    this.adminsEgyptValidSetting = new GeneralSettingModel();
    this.masterAdminValidSetting = new GeneralSettingModel();
    this.employeeValidSetting = new GeneralSettingModel();
    this.studentValidSetting = new GeneralSettingModel();
    this.supplierValidSetting = new GeneralSettingModel();
    this.parentValidSetting = new GeneralSettingModel();
    this.clientValidSetting = new GeneralSettingModel();

    this.adminsEgyptLoginCode = new GeneralSettingModel();
    this.masterAdminLoginCode = new GeneralSettingModel();
    this.employeeLoginCode = new GeneralSettingModel();
    this.studentLoginCode = new GeneralSettingModel();
    this.supplierLoginCode = new GeneralSettingModel();
    this.parentLoginCode = new GeneralSettingModel();
    this.clientLoginCode = new GeneralSettingModel();

    this.adminsEgyptEstablishmentsRole = new GeneralSettingModel();
    this.masterAdminEstablishmentsRole = new GeneralSettingModel();
    this.employeeEstablishmentsRole = new GeneralSettingModel();
    this.studentEstablishmentsRole = new GeneralSettingModel();
    this.supplierEstablishmentsRole = new GeneralSettingModel();
    this.parentEstablishmentsRole = new GeneralSettingModel();
    this.clientEstablishmentsRole = new GeneralSettingModel();

    this.storageSpacesAdminsEgypt = new GeneralSettingModel();
    this.storageSpacesMasterAdmin = new GeneralSettingModel();
    this.storageSpacesEmployees = new GeneralSettingModel();
    this.storageSpacesStudents = new GeneralSettingModel();
    this.storageSpacesSuppliers = new GeneralSettingModel();
    this.storageSpacesParents = new GeneralSettingModel();
    this.storageSpacesClients = new GeneralSettingModel();

    this.storageSpaceMediasAdminsEgypt = new GeneralSettingModel();
    this.storageSpaceMediasMasterAdmin = new GeneralSettingModel();
    this.storageSpaceMediasEmployees = new GeneralSettingModel();
    this.storageSpaceMediasStudents = new GeneralSettingModel();
    this.storageSpaceMediasSuppliers = new GeneralSettingModel();
    this.storageSpaceMediasParents = new GeneralSettingModel();
    this.storageSpaceMediasClients = new GeneralSettingModel();

    this.notifications = new GeneralSettingModel();
  }
  fillData(data) {
    if (data) {
      this.categories.fillData(data.categories);
      this.products.fillData(data.products);
      this.userWalletTransactions.fillData(data.userWalletTransactions);
      this.educationalStages.fillData(data.educationalStages);

      this.users.fillData(data.users);
      this.masterAdmins.fillData(data.masterAdmins);
      this.employees.fillData(data.employees);
      this.students.fillData(data.students);
      this.suppliers.fillData(data.suppliers);
      this.parents.fillData(data.parents);
      this.clients.fillData(data.clients);
      this.adminsEgypt.fillData(data.adminsEgypt);

      this.adminsEgyptFamily.fillData(data.adminsEgyptFamily);
      this.masterAdminFamily.fillData(data.masterAdminFamily);
      this.employeeFamily.fillData(data.employeeFamily);
      this.studentFamily.fillData(data.studentFamily);
      this.supplierFamily.fillData(data.supplierFamily);
      this.parentFamily.fillData(data.parentFamily);
      this.clientFamily.fillData(data.clientFamily);

      this.adminsEgyptValidSetting.fillData(data.adminsEgyptValidSetting);
      this.masterAdminValidSetting.fillData(data.masterAdminValidSetting);
      this.employeeValidSetting.fillData(data.employeeValidSetting);
      this.studentValidSetting.fillData(data.studentValidSetting);
      this.supplierValidSetting.fillData(data.supplierValidSetting);
      this.parentValidSetting.fillData(data.parentValidSetting);
      this.clientValidSetting.fillData(data.clientValidSetting);

      this.adminsEgyptLoginCode.fillData(data.adminsEgyptLoginCode);
      this.masterAdminLoginCode.fillData(data.masterAdminLoginCode);
      this.employeeLoginCode.fillData(data.employeeLoginCode);
      this.studentLoginCode.fillData(data.studentLoginCode);
      this.supplierLoginCode.fillData(data.supplierLoginCode);
      this.parentLoginCode.fillData(data.parentLoginCode);
      this.clientLoginCode.fillData(data.clientLoginCode);

      this.adminsEgyptEstablishmentsRole.fillData(
        data.adminsEgyptEstablishmentsRole
      );
      this.masterAdminEstablishmentsRole.fillData(
        data.masterAdminEstablishmentsRole
      );
      this.employeeEstablishmentsRole.fillData(data.employeeEstablishmentsRole);
      this.studentEstablishmentsRole.fillData(data.studentEstablishmentsRole);
      this.supplierEstablishmentsRole.fillData(data.supplierEstablishmentsRole);
      this.parentEstablishmentsRole.fillData(data.parentEstablishmentsRole);
      this.clientEstablishmentsRole.fillData(data.clientEstablishmentsRole);

      this.storageSpacesAdminsEgypt.fillData(data.storageSpacesAdminsEgypt);
      this.storageSpacesMasterAdmin.fillData(data.storageSpacesMasterAdmin);
      this.storageSpacesEmployees.fillData(data.storageSpacesEmployees);
      this.storageSpacesStudents.fillData(data.storageSpacesStudents);
      this.storageSpacesSuppliers.fillData(data.storageSpacesSuppliers);
      this.storageSpacesParents.fillData(data.storageSpacesParents);
      this.storageSpacesClients.fillData(data.storageSpacesClients);

      this.storageSpaceMediasAdminsEgypt.fillData(
        data.storageSpaceMediasAdminsEgypt
      );
      this.storageSpaceMediasMasterAdmin.fillData(
        data.storageSpaceMediasMasterAdmin
      );
      this.storageSpaceMediasEmployees.fillData(
        data.storageSpaceMediasEmployees
      );
      this.storageSpaceMediasStudents.fillData(data.storageSpaceMediasStudents);
      this.storageSpaceMediasSuppliers.fillData(
        data.storageSpaceMediasSuppliers
      );
      this.storageSpaceMediasParents.fillData(data.storageSpaceMediasParents);
      this.storageSpaceMediasClients.fillData(data.storageSpaceMediasClients);

      this.notifications.fillData(data.notifications);
    } else {
      this.setInitialValue();
    }
  }
}
