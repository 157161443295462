import { DEFAULT_PAGES } from "./../utils/constants";
import { USER_TYPE } from "./../utils/constantLists";
import { checkPrivilege } from "./../utils/functions";
import i18n from "./../i18n";
import store from "./../store";
import * as privilegeHelper from "./../utils/privilegeHelper";

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.loggedIn });
  } else {
    next();
  }
};
export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.notLoggedIn });
  } else {
    next();
  }
};

export const handlePrivilege = (to, from, next, privilegeStatus) => {
  ifNotAuthenticated(to, from, next);
  if (privilegeStatus) {
    next();
  } else {
    next({ name: "Error404" });
  }
  if (store.getters.invalidLoginCodeStatus) {
    next({ name: "InvalidLoginClode" });
  } else {
    next();
  }
};

export const ifInvalidLoginClode = (to, from, next) => {
  ifNotAuthenticated(to, from, next);
  if (store.getters.invalidLoginCodeStatus) {
    next();
  } else {
    next({ name: "Error404" });
  }
};

const routes = [
  {
    path: "/invalid-login-clode",
    name: "InvalidLoginClode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodesUseCode.vue"),
    beforeEnter: ifInvalidLoginClode,

    meta: {
      // layout: "auth",
      pageName: "InvalidLoginClode",
      pageIcon: "homepage.svg",
    },
  },
  //#region General
  {
    path: "/test",
    name: "Test",
    component: () => import("./../views/general/Test.vue"),
    meta: {
      layout: "auth",
      pageName: "test",
      pageIcon: "homepage.svg",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("./../views/home/Home.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "auth",
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
    },
  },
  //#endregion General

  //#region Auth
  {
    path: "/auth/login",
    name: "UserLogin",
    component: () => import("./../views/users/UserLogin"),
    beforeEnter: ifAuthenticated,
    meta: {
      layout: "auth",
      pageName: i18n.t("login"),
      pageIcon: "homepage.svg",
    },
  },
  //#endregion Auth

  //#region Users
  {
    path: "/users/admins-egypt/:userToken?",
    name: "AdminsEgypt",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAdminsEgypt())
      );
    },
    meta: {
      pageName: i18n.t("adminsEgypt.modelName"),
      pageIcon: "adminsEgypt.svg",
      userTypeToken: USER_TYPE.AdminsEgypt,
      modelName: "adminsEgypt",
      StorageSpacesRoute: "StorageSpacesAdminsEgypt",
    },
    props: true,
  },
  {
    path: "/users/master-admin/:userToken?",
    name: "MasterAdmin",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasMasterAdmin())
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      userTypeToken: USER_TYPE.MasterAdmin,
      modelName: "masterAdmins",
      StorageSpacesRoute: "StorageSpacesMasterAdmin",
    },
    props: true,
  },
  {
    path: "/users/employees/:userToken?",
    name: "Employees",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEmployee())
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      userTypeToken: USER_TYPE.Employee,
      modelName: "employees",
      StorageSpacesRoute: "StorageSpacesEmployee",
    },
    props: true,
  },
  {
    path: "/users/students/:userToken?",
    name: "Students",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasStudent())
      );
    },
    meta: {
      pageName: i18n.t("students.modelName"),
      pageIcon: "students.svg",
      userTypeToken: USER_TYPE.Student,
      modelName: "students",
      StorageSpacesRoute: "StorageSpacesStudent",
    },
    props: true,
  },
  {
    path: "/users/parents/:userToken?",
    name: "Parents",
    component: () => import("./../views/users/Users"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasParent())
      );
    },
    meta: {
      pageName: i18n.t("parents.modelName"),
      pageIcon: "parents.svg",
      userTypeToken: USER_TYPE.Parent,
      modelName: "parents",
      StorageSpacesRoute: "StorageSpacesParent",
    },
    props: true,
  },
  //#endregion Users

  //#region UserAppSettings
  {
    path: "/user-app-settings",
    name: "UserAppSettings",
    component: () => import("./../views/userAppSettings/UserAppSettings.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: {
      pageName: i18n.t("UserAppSettings.modelName"),
      pageIcon: "UserAppSettings.svg",
    },
  },
  //#endregion UserAppSettings

  //#region Settings

  //#region SettingsOther
  {
    path: "/settings/settings-others",
    name: "SettingsOthers",
    component: () =>
      import("./../views/settings/settingsOthers/SettingsOthers.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasSettingsOthers())
      );
    },
    meta: {
      pageName: i18n.t("settings-other"),
      pageIcon: "settings-other.svg",
    },
  },

  //#region Categories
  {
    path: "/settings/settings-others/categories",
    name: "Categories",
    component: () =>
      import("./../views/settings/settingsOthers/categories/Categories.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasCategory())
      );
    },
    meta: {
      pageName: i18n.t("Categories.modelName"),
      pageIcon: "categories.svg",
    },
  },
  //#endregion Categories

  //#region Products
  {
    path: "/settings/settings-others/products",
    name: "Products",
    component: () =>
      import("./../views/settings/settingsOthers/products/Products.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasProduct())
      );
    },
    meta: {
      pageName: i18n.t("Products.modelName"),
      pageIcon: "products.svg",
    },
  },
  //#endregion Products

  //#region UserWalletTransactions
  {
    path: "/settings/settings-others/userWalletTransactions/:userToken?",
    name: "UserWalletTransactions",
    component: () =>
      import(
        "./../views/settings/settingsOthers/userWalletTransactions/UserWalletTransactions.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasUserWalletTransaction())
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("UserWalletTransactions.modelName"),
      pageIcon: "userWalletTransactions.svg",
    },
  },
  //#endregion UserWalletTransactions

  //#region EducationalStages
  {
    path: "/settings/settings-others/educational-stages",
    name: "EducationalStages",
    component: () =>
      import(
        "./../views/settings/settingsOthers/educationalStages/EducationalStages.vue"
      ),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEducationalStage())
      );
    },
    meta: {
      pageName: i18n.t("EducationalStages.modelName"),
      pageIcon: "educationalStages.svg",
    },
  },
  //#endregion EducationalStages

  //#endregion SettingsOther

  //#endregion Settings

  //#region UserSettings

  //#region UserSettingsTabs

  {
    path: "/user-settings/master-admin/",
    name: "UserValidSettingsTabsMasterAdmin",
    component: () => import("./../views/settings/UserSettings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasUserValidSettingsTabsMasterAdmin())
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      userTypeToken: USER_TYPE.MasterAdmin,
    },
    props: true,
  },
  {
    path: "/user-settings/employee/",
    name: "UserValidSettingsTabsEmployee",
    component: () => import("./../views/settings/UserSettings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasUserValidSettingsTabsEmployee())
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      userTypeToken: USER_TYPE.Employee,
    },
    props: true,
  },
  {
    path: "/user-settings/student/",
    name: "UserValidSettingsTabsStudent",
    component: () => import("./../views/settings/UserSettings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasUserValidSettingsTabsStudent())
      );
    },
    meta: {
      pageName: i18n.t("students.modelName"),
      pageIcon: "students.svg",
      userTypeToken: USER_TYPE.Student,
    },
    props: true,
  },

  {
    path: "/user-settings/parent/",
    name: "UserValidSettingsTabsParent",
    component: () => import("./../views/settings/UserSettings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasUserValidSettingsTabsParent())
      );
    },
    meta: {
      pageName: i18n.t("parents.modelName"),
      pageIcon: "parents.svg",
      userTypeToken: USER_TYPE.Parent,
    },
    props: true,
  },

  //#endregion UserSettingsTabs

  //#endregion UserSettings

  //#region EstablishmentRoles

  {
    path: "/user-settings/establishment-roles/admins-egypt",
    name: "AdminsEgyptEstablishmentsRole",
    component: () =>
      import("./../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAdminsEgyptEstablishmentsRole())
      );
    },
    meta: {
      pageName: i18n.t("EstablishmentRoles.adminsEgypt.modelName"),
      pageIcon: "adminsEgypt.svg",
      userTypeToken: USER_TYPE.AdminsEgypt,
      modelName: "adminsEgyptEstablishmentsRole",
    },
    props: true,
  },
  {
    path: "/user-settings/establishment-roles/master-admin",
    name: "MasterAdminEstablishmentsRole",
    component: () =>
      import("./../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasMasterAdminEstablishmentsRole())
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      userTypeToken: USER_TYPE.MasterAdmin,
      modelName: "masterAdminEstablishmentsRole",
    },
    props: true,
  },
  {
    path: "/user-settings/establishment-roles/employee",
    name: "EmployeeEstablishmentsRole",
    component: () =>
      import("./../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEmployeeEstablishmentsRole())
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      userTypeToken: USER_TYPE.Employee,
      modelName: "employeeEstablishmentsRole",
    },
    props: true,
  },
  {
    path: "/user-settings/establishment-roles/student",
    name: "StudentEstablishmentsRole",
    component: () =>
      import("./../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasStudentEstablishmentsRole())
      );
    },
    meta: {
      pageName: i18n.t("students.modelName"),
      pageIcon: "students.svg",
      userTypeToken: USER_TYPE.Student,
      modelName: "studentEstablishmentsRole",
    },
    props: true,
  },

  {
    path: "/user-settings/establishment-roles/parent",
    name: "ParentEstablishmentsRole",
    component: () =>
      import("./../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasParentEstablishmentsRole())
      );
    },
    meta: {
      pageName: i18n.t("parents.modelName"),
      pageIcon: "parents.svg",
      userTypeToken: USER_TYPE.Parent,
      modelName: "parentEstablishmentsRole",
    },
    props: true,
  },

  //#endregion EstablishmentRoles

  //#region UserLoginCodes

  {
    path: "/user-settings/user-login-codes/admins-egypt/:userToken?",
    name: "AdminsEgyptLoginCode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasAdminsEgyptLoginCode())
      );
    },
    meta: {
      pageName: i18n.t("UserLoginCodes.adminsEgypt.modelName"),
      pageIcon: "adminsEgypt.svg",
      userTypeToken: USER_TYPE.AdminsEgypt,
      modelName: "adminsEgyptLoginCode",
    },
    props: true,
  },
  {
    path: "/user-settings/user-login-codes/master-admin/:userToken?",
    name: "MasterAdminLoginCode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasMasterAdminLoginCode())
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      userTypeToken: USER_TYPE.MasterAdmin,
      modelName: "masterAdminLoginCode",
    },
    props: true,
  },
  {
    path: "/user-settings/user-login-codes/employee/:userToken?",
    name: "EmployeeLoginCode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasEmployeeLoginCode())
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      userTypeToken: USER_TYPE.Employee,
      modelName: "employeeLoginCode",
    },
    props: true,
  },
  {
    path: "/user-settings/user-login-codes/student/:userToken?",
    name: "StudentLoginCode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasStudentLoginCode())
      );
    },
    meta: {
      pageName: i18n.t("students.modelName"),
      pageIcon: "students.svg",
      userTypeToken: USER_TYPE.Student,
      modelName: "studentLoginCode",
    },
    props: true,
  },

  {
    path: "/user-settings/user-login-codes/parent/:userToken?",
    name: "ParentLoginCode",
    component: () =>
      import("./../views/settings/userLoginCodes/UserLoginCodes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(privilegeHelper.hasParentLoginCode())
      );
    },
    meta: {
      pageName: i18n.t("parents.modelName"),
      pageIcon: "parents.svg",
      userTypeToken: USER_TYPE.Parent,
      modelName: "parentLoginCode",
    },
    props: true,
  },

  //#endregion UserLoginCodes

  //#region ModulePrivilege
  {
    path: "/privileges/edit/:establishmentRoleToken?/:modelName?",
    name: "Privileges",
    component: () =>
      import("./../views/modulePrivileges/ModulePrivilegeEdit.vue"),
    beforeEnter: (to, from, next) => {
      // console.log(to.params.modelName);
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          privilegeHelper.hasEstablishmentRoleEdit(to.params.modelName)
        )
      );
    },
    props: true,
    meta: {
      pageName: i18n.t("Privileges.modelName"),
      pageIcon: "privileges.svg",
      currentPage: "Privilege",
      currentSubPage: "",
    },
  },
  //#endregion ModulePrivilege

  //#region Error
  {
    path: "/error-404",
    name: "Error404",
    component: () => import("./../views/errors/Error-404.vue"),
    meta: {
      pageName: i18n.t("pageNotFound"),
      pageIcon: "404-icon.svg",
      currentPage: "",
      currentSubPage: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Error404" },
  },
  //#endregion Error
];

export default routes;
